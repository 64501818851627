<template lang="pug">
form(@submit.prevent="submitForm" v-if="!formSent")
  AppCard(:title="$t('userOptions.newUser')")
    AppAlert.mb-4(:title="$t('register.error_title')" :subtitle="$t('register.error_subtitle')" v-if="hasError")
    AppFormInput(type="text" :label="$t('quickpayment.service_number')" name="service_number" @update:value="contract = $event" required)
    AppButton.mb-0(:loading="loading" :disabled="loading" :label="$t('common.send')")
AppAlertCard(:title="$t('password_reset.valid_email')" :subtitle="$t('password_reset.check_mail')" v-else)
</template>

<script>
import { onMounted, ref } from "vue";

import { store } from "@/store";
import AppAlert from "@/components/AppAlert";
import AppAlertCard from "@/components/AppAlertCard";
import AppButton from "@/components/AppButton";
import AppFormInput from "@/components/Form/AppFormInput";
import AppCard from "@/components/AppCard";

import { axios } from "@/utils/axios";

export default {
  components: { AppAlert, AppAlertCard, AppButton, AppFormInput, AppCard },
  setup() {
    const contract = ref(null);
    const formSent = ref(false);
    const loading = ref(false);
    const hasError = ref(false);

    async function submitForm() {
      try {
        if (!contract.value || loading.value) {
          return;
        }

        loading.value = true;

        await axios.get(`/contact/userapp/${contract.value}`);

        formSent.value = true;
      } catch (error) {
        hasError.value = true;
        // TODO: mostrar una alerta de error
      } finally {
        loading.value = false;
      }
    }

    onMounted(async () => {
      try {
        store.APP_LOADING = true;
      } finally {
        store.APP_LOADING = false;
      }
    });

    return {
      contract,
      submitForm,
      formSent,
      loading,
      hasError,
    };
  },
};
</script>
